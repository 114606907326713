import '../App.css';
import React from 'react';

function Footer() {
	return (
        <div>
            <hr />
            <footer>&copy; Copyright 2022 11CreativeThings</footer>
        </div>
	);
}

export default Footer;
