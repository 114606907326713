import '../App.css';
import logo from "../logo_v1.svg";
import React from 'react';

function Logo(props) {
	return (
		<h1 className={"App-logo"} onClick={()=>window.location='/'}>
				<img src={logo}
				     alt={"11 Creative Things"}
				     className={"animate__animated animate__backInDown"} />

		</h1>
	);
}

export default Logo;
