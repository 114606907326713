import Links from './components/Links.js';
import React from 'react';
import './App.css';

function App() {    
    return (
        <div className="App">
            <header className="App-header">                
                <Links setView="links"/>
            </header>
        </div>
    );
}

export default App;
