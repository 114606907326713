import '../App.css';
import ReactGA from 'react-ga';
import React from 'react';

ReactGA.initialize('UA-196541292-3');

function Links(props) {
	function handleOutboundLinkClicks(event) {
		ReactGA.event({
			category: 'Outbound Link',
			action: 'click',
			label: event.target.href,
			transport: 'beacon'
		});
	}

	return (
		<div className="App-links"  data-role="navbar">
			<ul>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://11creativesvgs.etsy.com"}>Etsy Shop</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"/downloads"}>FREE DOWNLOADS</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://www.amazon.com/shop/11creativethings/"}>Amazon Storefront</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://www.instagram.com/11creativethings/"}>Instagram</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://www.pinterest.com/11creativesvgs"}>Pinterest</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://vm.tiktok.com/ZMRpoRcwm/"}>Tiktok</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://www.facebook.com/11creativethings/"}>Facebook Page</a>
				</li>
				<li>
					<a onClick={handleOutboundLinkClicks} href={"https://surebonder.com/?ref=qRQtcdch1O_P"}>Get SUREBONDER</a>
				</li>
			</ul>
		</div>
	);
}

export default Links;
